<template>
	<!-- Orders History Timeline Card -->
	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ paddingTop: '12px', }">
		<template #title>
			<h6>最近订单</h6>
		</template>
		<a-timeline>
			<a-spin tip="加载中..." :spinning="spinning">
				<a-timeline-item v-for="(order, i) in historyOrder" :key="i" :color="order.paymentStatus | status2Color">
					¥{{ order.totalPrice }}
					<a-descriptions :column="1">
						<a-descriptions-item label="订单号">
							{{ order.outTradeNo }}
						</a-descriptions-item>
						<a-descriptions-item label="支付方式">
							{{ order.paymentMethod | method2Text }}
						</a-descriptions-item>
						<a-descriptions-item label="支付时间">
							{{ order.purchaseTime | timeFmt }}
						</a-descriptions-item>
					</a-descriptions>
				</a-timeline-item>
			</a-spin>
		</a-timeline>
		<div style="text-align: center;">
			<router-link to="/billing">
				<a-button type="primary" style="max-width: 200px;" block size="small">
					查看更多
				</a-button>
			</router-link>
		</div>
	</a-card>
	<!-- / Orders History Timeline Card -->
</template>

<script>

export default ({
	data() {
		return {
			historyOrder: [],
			spinning: true,
		}
	},
	computed: {
	},
	filters: {
		status2Color(val) {
			switch (val) {
				case "WAIT_BUYER_PAY":
					return "gray"
				case "TRADE_CLOSED":
					return "red"
				case "TRADE_SUCCESS":
					return "green"
				case "TRADE_FINISHED":
					return "green"
			}
		},
		timeFmt(val) {
			const date = new Date(val)
			return date.toLocaleString('zh-CN', { timeZone: 'Asia/Shanghai' })
		},
		method2Text(val) {
			let map = {
				'AliF2F': '支付宝',
				'Balance': '余额',
			}
			return map[val] ?? val
		}
	},
	mounted() {
		this.spinning = true
		this.$axios.get('/api/platform/purchaseRecord/top5Record')
			.then(res => {
				this.spinning = false
				this.historyOrder = res.data.records
			})
	},
})

</script>

<style scoped></style>